import './CommonHeader.css';
import tekscoutlogo from '../../assets/TS_logo_white.png';
import Navbar from 'react-bootstrap/Navbar';
import { Component } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";

class CommonHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: tekscoutlogo,
            imageAlt: ''
        };
    }
        
    componentDidMount() {
        document.documentElement.scrollTop = 0;
    }
    componentWillUnmount() {
        window.onscroll = null;
    }
    render(){
            return(
            <div className="nav-bar-common-container">
                <div className="nav-bar-common-left-container">
                    <Navbar>
                        <Navbar.Brand href="../.." className="tekscout-brand-logo common-logo">
                            <img src={this.state.imageSrc} className="tekscout-logo" alt="tekscoutLogo"></img>
                        </Navbar.Brand>
                        <div className='menu'>
                        <Nav>
                            <Link to='/'>Home</Link>
                        </Nav>
                        <Nav>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Services
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to='/permanent-staffing'>Permanent Staffing</Dropdown.Item>
                                    <Dropdown.Item as={Link} to='/contract-staffing'>Contract Staffing</Dropdown.Item>
                                    <Dropdown.Item as={Link} to='/recruiter-service'>Recruiter as a Service</Dropdown.Item>
                                    <Dropdown.Item as={Link} to='/executive-search'>Executive Search</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                        {/* <Nav>
                            <Link to='/pricing'>Pricing</Link>
                        </Nav> */}
                        <Nav>
                            <a target={"_blank"} rel="noreferrer" href='https://tekscout.springrecruit.com/careers'>Jobs</a>
                        </Nav>
                        <Nav>
                            <Link to='/about-us'>About Us</Link>
                        </Nav>
                        
                        <Nav>
                            <Link to='/contact-us/inquiry/inquiry'>Contact Us</Link>
                        </Nav>
                        </div>
                    </Navbar>
                </div>
            </div>
            
        );
    }
}

export default CommonHeader;