import './Footer.css';
import {Col, Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { faFacebook, faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import { faLocationArrow} from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state= {
            showModal: false,
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        }
    }
    handleModalShow  = () =>{
        this.setState({showModal: true});
    }
    handleModalClose = () =>{
        this.setState({showModal: false});
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div className="m-footer-main-container">
                    <Row className="footer-container">
                            <Col sm={12} >
                                <div>
                                    <div> Follow Us</div>
                                    <span className="fa-icon-spacing"><FontAwesomeIcon icon={faLocationArrow} /></span>
                                    <a className="fa-icon-spacing" href='https://www.linkedin.com/company/tekscout/' rel="noreferrer" target="_blank">
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                    <a className="fa-icon-spacing" href='https://www.facebook.com/tekscout' rel="noreferrer" target="_blank">
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </a>
                                    <div>&#169; tekscout solutions pvt ltd</div>
                                </div>
                                <div onClick={this.handleModalShow}>Privacy Policy</div>
                            </Col>
                            
                    </Row>
                    <Modal className="m-modal-dialog" scrollable show={this.state.showModal} onHide={this.handleModalClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>Privacy Policy</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>THIS PRIVACY POLICY TALKS ABOUT THE COLLECTION, 
                                USE AND DISCLOSURE OF USER INFORMATION GATHERED THROUGH THE WEBSITE. 
                                PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY CONTINUING TO USE THIS 
                                WEBSITE, YOU AGREE TO THE PRIVACY POLICY. IF YOU DO NOT AGREE TO THE PRIVACY POLICY, YOU MAY NOT USE 
                                THIS WEBSITE.</p>
                            <p>Those capitalized terms not defined herein, shall have the same meaning ascribed to them in the Terms of Use.</p>
                            <p>
                                <b>INTRODUCTION</b>
                                <ul>
                                    <li>We, tekscout solutions private limited(“tekscout”; “We”; “us”; “our”), own, maintain and operate the website www.tekscout.in (“Website”), which is a platform through which the Users are provided information, solutions, services for recruitment and job requirements. We are committed to protect the privacy of our Users (“Users”;”you”;”your”) and have made this privacy policy (“Privacy Policy”) to describe the procedures we adhere to for collecting, using, and disclosing your information. We recommend you to read this Privacy Policy carefully so that you understand our approach towards the use of your Personal Information (as defined below), while providing the Services to you.</li>
                                </ul>
                                <b>PERSONAL INFORMATION COLLECTED:</b>
                                <ul>
                                    <li>In order to avail the entire gamut of Services offered on this Website, you may be required to register and create a User Account with password (“User Account”). At the time of registration you shall be required to share certain information including but not limited to name, mobile no., email Id (“Personal Information”) with the Company. You should know that we use this Personal Information only for providing you with our Services and for our internal business purposes. You undertake that you shall be solely responsible for the accuracy and truthfulness of the Personal Information you share with us.Users who do not register with us for a User Account, may have limited access to the Website.</li>
                                </ul>
                                <b>GOVERNING STATUTE:</b>
                                <ul>
                                    <li>This Privacy Policy is governed by and is compliant with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011, which is designed to protect Personal Information of the Users of the Website; and other applicable rules and regulations related to privacy.</li>
                                </ul>
                                <b>USE OF PERSONAL INFROMATION :</b>
                                <ul>
                                    <li>We shall use the Personal Information in the following cases: (i) to provide you with our Services and e-mail you in the event you need additional support; (ii) to more effectively manage traffic on the Website (iii) to improve our Website and Services to you; (iv) to maintain and manage our User Accounts; (v) to assist you with technical difficulties; (vi) to manage our relationship with you; (vii) to comply with our legal or statutory obligations; (viii) to protect life and property, that of our Users and ourselves; (ix) to contact you about important announcements and other important things about your User Account on the Website.We may also send you any legally required notifications and certain notifications, including but not limited to, Service related notices or notices regarding a change to any of our policies. For example, we may send you a notice regarding server problems or scheduled maintenance to the Website.</li>
                                </ul>
                                <b>COOKIES :</b>
                                <ul>
                                    <li>We may set “cookies” on the use of the Website by Users. Cookies are small encrypted files, that a site or its service provider transfer to your computer’s hard drive through your web browser that enables the sites or service provider’s systems to recognize your browser and capture and remember certain information.</li>
                                </ul>
                                <b>DISLCOSURES :</b>
                                <ul>
                                    <li>We do not sell, rent, lease your Personal Information to anybody and will never do so. Keeping this in mind, we may disclose your Personal Information in the following cases:</li>
                                    <ul>
                                        <p><b>Administrators</b>We shall provide access to your Personal information to our authorized administratives for internal business purposes, who shall be under confidentiality obligations towards the same.</p>
                                        <p><b>Affiliates</b>We may provide Personal Information we collect to our affiliates. For example, we may disclose Personal Information to our affiliates in order to respond to your requests for information or Services, or to help limit your receipt of marketing materials you have requested not to receive.</p>
                                        <p><b>Service Providers or Business Partners</b>We may use certain trusted third party companies and individuals to help us provide, analyse, and improve the Services (including but not limited to data storage, maintenance services, database management, web analytics, payment processing, and improvement of the Service’s features. These third parties may have access to your information only for purposes of performing these tasks on our behalf and under obligations similar to those in this Privacy Policy. We may disclose your Personal Information to service providers who perform business functions or hosting services on our behalf and who may be located outside of India.</p>
                                        <p><b>Persons Who Acquire Our Assets or Business</b> If we sell or transfer any of our business or assets, certain Personal Information may be a part of that sale or transfer. In the event of such a sale or transfer, we will notify you.</p>
                                        <p><b>Legal and Regulatory Authorities</b>We may be required to disclose your Personal Information due to legal or regulatory requirements. In such instances, we reserve the right to disclose your Personal Information as required in order to comply with our legal obligations, including but not limited to complying with court orders, warrants, or discovery requests. We may also disclose Personal Information about our Users to law enforcement officers or others, in the good faith belief that such disclosure is reasonably necessary to enforce this Privacy Policy; respond to claims that any Personal Information violates the rights of third-parties; or protect the rights, property, or personal safety of the Company, our Users or the general public. You agree and acknowledge that we may not inform you prior to or after disclosures made according to this section.</p>
                                    </ul>
                                </ul>
                                <b>DATA RETENTION: </b>
                                <ul>
                                    <li>We will retain your Personal Information for as long as your registration with us is valid. We may also retain and use your Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. Consistent with these requirements, we will try to delete your Personal Information quickly upon reasonable written request. Please note, however, that there might be latency in deleting Personal Information from our servers and backed-up versions might exist after deletion.</li>
                                </ul>
                                <b>SECURITY :</b>
                                <ul>
                                    <li>We value your Personal Information, and protect it on our Website against loss, misuse or alteration by taking extensive security measures. In order to protect your Personal Information we have implemented adequate technology and will update these measures as new technology becomes available, as appropriate. Although we provide appropriate firewalls and protections, we cannot warrant the security of any Personal Information transmitted as our systems are not hack proof. Data pilferage due to unauthorized hacking, virus attacks, technical issues is possible and we take no liabilities or responsibilities for it.You are responsible for all actions that take place under your User Account. Therefore, we do not recommend that you disclose your User Account details and password to any third party. If you choose to share your User Account details and password or any Personal Information with third parties, you are solely responsible for the same. If you lose control of your password, you may lose substantial control over your Personal Information and may be subject to legally binding actions. If you feel that your User Account has been compromised, please contact us on info@tekscout.in immediately.</li>
                                </ul>
                                <b>ACCESSING AND MODIFYING PERSONAL INFORMATION </b>
                                <ul>
                                    <li>In case you need to access, review, and/or make changes to the Personal Information, you shall have to login to your User Account and change the requisite details. You shall keep your Personal Information updated to help us better serve you.</li>
                                </ul>
                                <b>ANCILLARY SERVICES:</b>
                                <ul>
                                    <li>We may provide you with certain ancillary services in the form of blogs, chat rooms, and news, for providing better Services and to receive your feedbacks. We can of course not control and be responsible for other parties’ use of the Personal Information which you make available to them through this Website. We encourage you to be careful about what Personal Information you disclose in this way.</li>
                                </ul>
                            </p>
                            <p>Subject to any applicable law, any communication shared by you via the Website or through the blogs, chat rooms, reviews or otherwise to us (including without limitation contents, images, audio, financial information, feedback etc. collectively “Feedback”) is on a non-confidential basis, and we are under no obligation to refrain from reproducing, publishing or otherwise using it in any way or for any purpose. You shall be responsible for the content and information contained in any Feedback shared by you to the Website or otherwise to us, including without limitation for its truthfulness and accuracy.
                                Sharing your Feedback with us, constitutes an assignment to us of all worldwide rights, titles and interests in all copyrights and other intellectual property rights in the Feedback.<br/>
                                <b>THIRD PARTY LINKS AND ADVERTISEMENTS:</b>
                                <ul>
                                    <li>We may have links to other websites and advertisements on our Website. We shall not be responsible for the privacy policies and practices of other websites. Such links and advertisements are subject to their respective privacy policies and any additional guidelines provided in relation to that use on their website.</li>
                                </ul>
                                <b>INDEMNIFICATION:</b>
                                <ul>
                                    <li>You agree to indemnify us, our subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees and hold us harmless from and against any claims and demand, including reasonable attorneys’ fees, made by any third party due to arising out of or relating to: (i) Personal Information and contents that you submit or share on the Website, (ii) your violation of the Privacy Policy, (iii) or your violation of rights of another Member.</li>
                                </ul>
                                <b>LIMITATION OF LIABILITY:</b>
                                <ul>
                                    <li>You expressly understand and agree that the Company shall not be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, information, details or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services offered by the Company; (ii) unauthorized access to or alteration of your Personal Information.</li>
                                </ul>
                                <b>GOVERNING LAWS AND DISPUTES:</b>
                                <ul>
                                    <li>This Agreement shall be construed and governed by the laws of India and courts of law at Pune shall have exclusive jurisdiction over such disputes without regard to principles of conflict of laws.</li>
                                </ul>
                                <b>CHANGES TO THIS POLICY:</b>
                                <ul>
                                    <li>We may update this Privacy Policy without notice to you. You are encouraged to check our Privacy Policy on a regular basis to be aware of the changes made to it. Continued use of the Website shall be deemed to be your acceptance of the Privacy Policy.</li>
                                </ul>
                            </p>
                            <p>This Privacy Policy was last modified on 28th January 2023.<br/><br/>
                                CONTACT US:
                                If you have questions or concerns or grievances regarding this Privacy Policy you can email us at our grievance email-id info@tekscout.in
                            </p>    
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        } else {
            return(
                <div className="footer-main-container">
                    <Row className="footer-container">
                            <Col md={4}>
                                <div>
                                    <div> Follow Us</div>
                                    <span className="fa-icon-spacing"><FontAwesomeIcon icon={faLocationArrow} /></span>
                                    <a className="fa-icon-spacing" href='https://www.linkedin.com/company/tekscout/' rel="noreferrer" target="_blank">
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                    <a className="fa-icon-spacing" href='https://www.facebook.com/tekscout' rel="noreferrer" target="_blank">
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </a>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>&#169; tekscout solutions pvt ltd</div>
                            </Col>
                            <Col md={4}>
                                <div className="p-policy" onClick={this.handleModalShow}>Privacy Policy</div>
                            </Col>
                            
                    </Row>
                    <Modal show={this.state.showModal} fullscreen={true} onHide={this.handleModalClose}>
                        <Modal.Header closeButton>
                        <Modal.Title>Privacy Policy</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>THIS PRIVACY POLICY TALKS ABOUT THE COLLECTION, 
                                USE AND DISCLOSURE OF USER INFORMATION GATHERED THROUGH THE WEBSITE. 
                                PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY CONTINUING TO USE THIS 
                                WEBSITE, YOU AGREE TO THE PRIVACY POLICY. IF YOU DO NOT AGREE TO THE PRIVACY POLICY, YOU MAY NOT USE 
                                THIS WEBSITE.</p>
                            <p>Those capitalized terms not defined herein, shall have the same meaning ascribed to them in the Terms of Use.</p>
                            <p>
                                <b>INTRODUCTION</b>
                                <ul>
                                    <li>We, tekscout solutions private limited(“tekscout”; “We”; “us”; “our”), own, maintain and operate the website www.tekscout.in (“Website”), which is a platform through which the Users are provided information, solutions, services for recruitment and job requirements. We are committed to protect the privacy of our Users (“Users”;”you”;”your”) and have made this privacy policy (“Privacy Policy”) to describe the procedures we adhere to for collecting, using, and disclosing your information. We recommend you to read this Privacy Policy carefully so that you understand our approach towards the use of your Personal Information (as defined below), while providing the Services to you.</li>
                                </ul>
                                <b>PERSONAL INFORMATION COLLECTED:</b>
                                <ul>
                                    <li>In order to avail the entire gamut of Services offered on this Website, you may be required to register and create a User Account with password (“User Account”). At the time of registration you shall be required to share certain information including but not limited to name, mobile no., email Id (“Personal Information”) with the Company. You should know that we use this Personal Information only for providing you with our Services and for our internal business purposes. You undertake that you shall be solely responsible for the accuracy and truthfulness of the Personal Information you share with us.Users who do not register with us for a User Account, may have limited access to the Website.</li>
                                </ul>
                                <b>GOVERNING STATUTE:</b>
                                <ul>
                                    <li>This Privacy Policy is governed by and is compliant with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011, which is designed to protect Personal Information of the Users of the Website; and other applicable rules and regulations related to privacy.</li>
                                </ul>
                                <b>USE OF PERSONAL INFROMATION :</b>
                                <ul>
                                    <li>We shall use the Personal Information in the following cases: (i) to provide you with our Services and e-mail you in the event you need additional support; (ii) to more effectively manage traffic on the Website (iii) to improve our Website and Services to you; (iv) to maintain and manage our User Accounts; (v) to assist you with technical difficulties; (vi) to manage our relationship with you; (vii) to comply with our legal or statutory obligations; (viii) to protect life and property, that of our Users and ourselves; (ix) to contact you about important announcements and other important things about your User Account on the Website.We may also send you any legally required notifications and certain notifications, including but not limited to, Service related notices or notices regarding a change to any of our policies. For example, we may send you a notice regarding server problems or scheduled maintenance to the Website.</li>
                                </ul>
                                <b>COOKIES :</b>
                                <ul>
                                    <li>We may set “cookies” on the use of the Website by Users. Cookies are small encrypted files, that a site or its service provider transfer to your computer’s hard drive through your web browser that enables the sites or service provider’s systems to recognize your browser and capture and remember certain information.</li>
                                </ul>
                                <b>DISLCOSURES :</b>
                                <ul>
                                    <li>We do not sell, rent, lease your Personal Information to anybody and will never do so. Keeping this in mind, we may disclose your Personal Information in the following cases:</li>
                                    <ul>
                                        <p><b>Administrators</b>We shall provide access to your Personal information to our authorized administratives for internal business purposes, who shall be under confidentiality obligations towards the same.</p>
                                        <p><b>Affiliates</b>We may provide Personal Information we collect to our affiliates. For example, we may disclose Personal Information to our affiliates in order to respond to your requests for information or Services, or to help limit your receipt of marketing materials you have requested not to receive.</p>
                                        <p><b>Service Providers or Business Partners</b>We may use certain trusted third party companies and individuals to help us provide, analyse, and improve the Services (including but not limited to data storage, maintenance services, database management, web analytics, payment processing, and improvement of the Service’s features. These third parties may have access to your information only for purposes of performing these tasks on our behalf and under obligations similar to those in this Privacy Policy. We may disclose your Personal Information to service providers who perform business functions or hosting services on our behalf and who may be located outside of India.</p>
                                        <p><b>Persons Who Acquire Our Assets or Business</b> If we sell or transfer any of our business or assets, certain Personal Information may be a part of that sale or transfer. In the event of such a sale or transfer, we will notify you.</p>
                                        <p><b>Legal and Regulatory Authorities</b>We may be required to disclose your Personal Information due to legal or regulatory requirements. In such instances, we reserve the right to disclose your Personal Information as required in order to comply with our legal obligations, including but not limited to complying with court orders, warrants, or discovery requests. We may also disclose Personal Information about our Users to law enforcement officers or others, in the good faith belief that such disclosure is reasonably necessary to enforce this Privacy Policy; respond to claims that any Personal Information violates the rights of third-parties; or protect the rights, property, or personal safety of the Company, our Users or the general public. You agree and acknowledge that we may not inform you prior to or after disclosures made according to this section.</p>
                                    </ul>
                                </ul>
                                <b>DATA RETENTION: </b>
                                <ul>
                                    <li>We will retain your Personal Information for as long as your registration with us is valid. We may also retain and use your Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. Consistent with these requirements, we will try to delete your Personal Information quickly upon reasonable written request. Please note, however, that there might be latency in deleting Personal Information from our servers and backed-up versions might exist after deletion.</li>
                                </ul>
                                <b>SECURITY :</b>
                                <ul>
                                    <li>We value your Personal Information, and protect it on our Website against loss, misuse or alteration by taking extensive security measures. In order to protect your Personal Information we have implemented adequate technology and will update these measures as new technology becomes available, as appropriate. Although we provide appropriate firewalls and protections, we cannot warrant the security of any Personal Information transmitted as our systems are not hack proof. Data pilferage due to unauthorized hacking, virus attacks, technical issues is possible and we take no liabilities or responsibilities for it.You are responsible for all actions that take place under your User Account. Therefore, we do not recommend that you disclose your User Account details and password to any third party. If you choose to share your User Account details and password or any Personal Information with third parties, you are solely responsible for the same. If you lose control of your password, you may lose substantial control over your Personal Information and may be subject to legally binding actions. If you feel that your User Account has been compromised, please contact us on info@tekscout.in immediately.</li>
                                </ul>
                                <b>ACCESSING AND MODIFYING PERSONAL INFORMATION </b>
                                <ul>
                                    <li>In case you need to access, review, and/or make changes to the Personal Information, you shall have to login to your User Account and change the requisite details. You shall keep your Personal Information updated to help us better serve you.</li>
                                </ul>
                                <b>ANCILLARY SERVICES:</b>
                                <ul>
                                    <li>We may provide you with certain ancillary services in the form of blogs, chat rooms, and news, for providing better Services and to receive your feedbacks. We can of course not control and be responsible for other parties’ use of the Personal Information which you make available to them through this Website. We encourage you to be careful about what Personal Information you disclose in this way.</li>
                                </ul>
                            </p>
                            <p>Subject to any applicable law, any communication shared by you via the Website or through the blogs, chat rooms, reviews or otherwise to us (including without limitation contents, images, audio, financial information, feedback etc. collectively “Feedback”) is on a non-confidential basis, and we are under no obligation to refrain from reproducing, publishing or otherwise using it in any way or for any purpose. You shall be responsible for the content and information contained in any Feedback shared by you to the Website or otherwise to us, including without limitation for its truthfulness and accuracy.
                                Sharing your Feedback with us, constitutes an assignment to us of all worldwide rights, titles and interests in all copyrights and other intellectual property rights in the Feedback.<br/>
                                <b>THIRD PARTY LINKS AND ADVERTISEMENTS:</b>
                                <ul>
                                    <li>We may have links to other websites and advertisements on our Website. We shall not be responsible for the privacy policies and practices of other websites. Such links and advertisements are subject to their respective privacy policies and any additional guidelines provided in relation to that use on their website.</li>
                                </ul>
                                <b>INDEMNIFICATION:</b>
                                <ul>
                                    <li>You agree to indemnify us, our subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees and hold us harmless from and against any claims and demand, including reasonable attorneys’ fees, made by any third party due to arising out of or relating to: (i) Personal Information and contents that you submit or share on the Website, (ii) your violation of the Privacy Policy, (iii) or your violation of rights of another Member.</li>
                                </ul>
                                <b>LIMITATION OF LIABILITY:</b>
                                <ul>
                                    <li>You expressly understand and agree that the Company shall not be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, information, details or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services offered by the Company; (ii) unauthorized access to or alteration of your Personal Information.</li>
                                </ul>
                                <b>GOVERNING LAWS AND DISPUTES:</b>
                                <ul>
                                    <li>This Agreement shall be construed and governed by the laws of India and courts of law at Pune shall have exclusive jurisdiction over such disputes without regard to principles of conflict of laws.</li>
                                </ul>
                                <b>CHANGES TO THIS POLICY:</b>
                                <ul>
                                    <li>We may update this Privacy Policy without notice to you. You are encouraged to check our Privacy Policy on a regular basis to be aware of the changes made to it. Continued use of the Website shall be deemed to be your acceptance of the Privacy Policy.</li>
                                </ul>
                            </p>
                            <p>This Privacy Policy was last modified on 28th January 2023.<br/><br/>
                                CONTACT US:
                                If you have questions or concerns or grievances regarding this Privacy Policy you can email us at our grievance email-id info@tekscout.in
                            </p>    
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                            Close
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        }
    }
}

export default Footer;