import './AboutUs.css';
import React, { Component } from 'react';
import CommonHeader  from '../ComonHeader/CommonHeader';
import Header  from '../Header/Header';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';

class AboutUs extends Component { 
    constructor(props) {
        super(props);
        this.state= {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        }
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className='m-abs-header-image'></div>
                    <div className='m-about-us-page-main-container'>
                        <div className='m-about-us-header'>
                            <h3><b>About Us</b></h3>
                        </div>
                        <div className='m-about-us-content'>
                            <p>At Tekscout, we are a HR consulting organization that is focused on delivering value-added
                                services to our clients. We cater to companies of all sizes, from startups to medium-sized
                                businesses, and across a range of industries including e-commerce and IT & ITes.</p>

                            <p>As SMEs often require support in non-core business operations, our goal is to leverage our expertise, experience, 
                                and network to provide a comprehensive HR service. We are dedicated to handling all corporate HR activities for our clients.</p>

                            <p>Our team at Tekscout delivers a unique combination of skills to support organizations in their recruitment efforts, 
                                improve their efficiency and quality, and minimize hiring risks. We have grown into a trusted resource partner 
                                for staffing solutions due to our large workforce and well-established operations.</p>
                            <p>With a targeted approach and ongoing investments in technology and global best practices, we continue to lead the HR 
                                outsourcing industry. By blending our experience, we help firms hire both permanent and flexible staff while 
                                enhancing productivity, quality, and reducing employment-related risks.</p>
                            <div>
                                <div className="m-about-us-box-1-question about-us-box-1-question"><b>What we deliver?</b></div>
                                <p>Tekscout delivers the ideal blend of skills to help organizations hire permanent staff, improve efficiency and 
                                    quality, and lower risk associated with hiring staff.</p>
                                <p>With robust operation delivery capabilities we have developed into a reliable resource partner
                                    that provides personnel solutions. Tekscout has a targeted approach. With ongoing investments
                                    to improve operational and delivery standard, along with use of global best practices and
                                    technology, we have been able to achieve the top spot in HR outsourcing.</p>
                                <div className="bullet-point-fa margin-bot">
                                    <FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>
                                    We combine the ideal mix of experience to enable firms hire flexible and permanent staff, 
                                    improve productivity and quality, and lower employment-related risk.</div>
                                <div className="bullet-point-fa">
                                    <FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>
                                        With a strong workforce and extensive operations, we have developed into a reliable resource partner that provides staffing solutions.</div>
                            </div>
                            <div class="m-about-us-container">
                                <h4>
                                    <b>Our Values</b>
                                </h4>
                                <div className="m-abs-cards_item">
                                    <div className="m-abs-cards">
                                        <div className="abs-card m-border-radius m-abt-box-margin-bottom" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="m-title abs-card_title color-1">Integrity</h2>
                                                    <div className="m-card-text abs-card_text">
                                                        <p>Fair, Honest, Transparent & Ethical in our conduct.
                                                        </p>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="abs-card m-border-radius m-abt-box-margin-bottom" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="m-title abs-card_title color-2">Excellence</h2>
                                                    <div className="m-card-text abs-card_text">
                                                    <p> We strive to provide unique data driven quality services.
                                                    </p>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="abs-card m-border-radius m-abt-box-margin-bottom" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="m-title abs-card_title color-3">Respect</h2>
                                                    <div className="m-card-text abs-card_text">
                                                        <p>We understand the value of time and respect it.
                                                        </p>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="abs-card m-border-radius m-abt-box-margin-bottom" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="m-title abs-card_title color-4">Purposefulness</h2>
                                                    <div className="m-card-text abs-card_text">
                                                        <p>We intent to solve a cause and not just earn revenues.
                                                        </p>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className='abs-header-image'></div>
                    <div className='about-us-page-main-container'>
                        <div className='about-us-header'>
                            <h3>About Us</h3>
                        </div>
                        <div className='about-us-content'>
                            <p>At Tekscout, we are a HR consulting organization that is focused on delivering value-added
                                services to our clients. We cater to companies of all sizes, from startups to medium-sized
                                businesses, and across a range of industries including e-commerce and IT & ITes.</p>

                            <p>As SMEs often require support in non-core business operations, our goal is to leverage our expertise, experience, 
                                and network to provide a comprehensive HR service. We are dedicated to handling all corporate HR activities for our clients.</p>

                            <p>Our team at Tekscout delivers a unique combination of skills to support organizations in their recruitment efforts, 
                                improve their efficiency and quality, and minimize hiring risks. We have grown into a trusted resource partner 
                                for staffing solutions due to our large workforce and well-established operations.</p>
                            <p>With a targeted approach and ongoing investments in technology and global best practices, we continue to lead the HR 
                                outsourcing industry. By blending our experience, we help firms hire both permanent and flexible staff while 
                                enhancing productivity, quality, and reducing employment-related risks.</p>
                            <div>
                                <div className="about-us-box-1-question"><b>What we deliver?</b></div>
                                <p>Tekscout delivers the ideal blend of skills to help organizations hire permanent staff, improve efficiency and 
                                    quality, and lower risk associated with hiring staff.</p>
                                <p>With robust operation delivery capabilities we have developed into a reliable resource partner
                                    that provides personnel solutions. Tekscout has a targeted approach. With ongoing investments
                                    to improve operational and delivery standard, along with use of global best practices and
                                    technology, we have been able to achieve the top spot in HR outsourcing.</p>
                                <div className="bullet-point-fa margin-bot">
                                    <FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>
                                    We combine the ideal mix of experience to enable firms hire flexible and permanent staff, 
                                    improve productivity and quality, and lower employment-related risk.</div>
                                <div className="bullet-point-fa">
                                    <FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>
                                        With a strong workforce and extensive operations, we have developed into a reliable resource partner that provides staffing solutions.</div>
                            </div>
                            <div class="about-us-container">
                                <h4>
                                        <b>Our Values</b>
                                    </h4>
                                <div className="abs-cards_item">
                                    <div className="abs-cards">
                                        <div className="abs-card radius" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="abs-title-1 abs-card_title color-1">Integrity</h2>
                                                    <div className="radius abs-card_text">
                                                        <p>Fair, Honest, Transparent & Ethical in our conduct.
                                                        </p>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="abs-card radius" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="abs-title-1 abs-card_title color-2">Excellence</h2>
                                                    <div className="radius abs-card_text">
                                                    <p> We strive to provide unique data driven quality services.
                                                    </p>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="abs-card radius" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="abs-title-1 abs-card_title color-3">Respect</h2>
                                                    <div className="radius abs-card_text">
                                                        <p>We understand the value of time and respect it.
                                                        </p>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="abs-card radius" tabindex="0">
                                            <div className="abs-card_content">
                                                <h2 className="abs-title-1 abs-card_title color-4">Purposefulness</h2>
                                                    <div className="radius abs-card_text">
                                                        <p>We intent to solve a cause and not just earn revenues.
                                                        </p>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default AboutUs;