import './App.css';
import React, { Component } from 'react';
import Homepage from './components/Homepage/Homepage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faPhoneAlt, faLocationArrow, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Pricing from './components/PricingPlan/Pricing';
import AboutUs from './components/AboutUs/AboutUs';
import ExecutiveSearch from './components/Services/ExecutiveSearch/ExecutiveSearch';
import PermanentStaffing from './components/Services/PermanentStaffing/PermanentStaffing';
import RecruiterService from './components/Services/RecruiterService/RecruiterService';
import ContractStaffing from './components/Services/ContractStaffing/ContractStaffing';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';

class App extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  render() {
    return (
      <div>
        <Router basename='/'>
          <Routes>
            <Route path="/" element={<Homepage/>}/>
            <Route path="pricing" element={<Pricing/>}/>
            <Route path="about-us" element={<AboutUs/>}/>
            <Route path="executive-search" element={<ExecutiveSearch/>}/>
            <Route path="permanent-staffing" element={<PermanentStaffing/>}/>
            <Route path="recruiter-service" element={<RecruiterService/>}/>
            <Route path="contract-staffing" element={<ContractStaffing/>}/>
            <Route path="contact-us/:type/:page" element={<ContactUs/>}/>
          </Routes>
        </Router>
        <Footer/>
      </div>
    );
  }
}
library.add(faBars, faPhoneAlt, faLocationArrow, faEnvelope);
export default App;
