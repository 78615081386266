import './ContractStaffing.css';
import React, { Component } from 'react';
import CommonHeader  from '../../ComonHeader/CommonHeader';
import Header  from '../../Header/Header';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
class ContractStaffing extends Component { 
    constructor(props) {
        super(props);
        this.state= {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        }
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className='m-contract-staffing-header-image'></div>
                    <div className='m-contract-staffing-main-container'>
                        <div className='m-perm-staffing-header'>
                            <h3>Contract Staffing</h3>
                        </div>
                        <div className='m-contract-staffing-content'>
                            <p>Tekscout offers contract staffing to help businesses find, hire, and manage temporary or
                                contract employees. Through our services you can bring flexibility and scale to your
                                operation / business conduct. All the candidate for consideration for a given role are
                                vetted and screened as per industry standards. We also give the option to you to have
                                rounds of discussion with the candidates before taking them onboard for any role.</p>
                            <div className="exe-search-box-1-question"><b>Why should you outsource your recruitment to tekscout? </b></div>
                            <div className='m-process-box-flex-cs'>
                                <Card className='m-process-box-flex-cs-card'>
                                    <Card.Body className='m-process-box-flex-cs-card-body'>
                                        <Card.Header className='m-process-box-flex-cs-card-header'>Flexibility</Card.Header>
                                        <Card.Text className='m-process-box-flex-cs-card-text'>
                                            Our Contract staffing service allows you to bring in workers for specific
                                            projects or periods of time, without committing to a long-term employment relationship.
                                            This will allow you to manage your workforce more flexibly, and respond to changes in
                                            demand or business conditions.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-cs'>
                                <Card className='m-process-box-flex-cs-card'>
                                    <Card.Body className='m-process-box-flex-cs-card-body'>
                                        <Card.Header className='m-process-box-flex-cs-card-header'>Access to specialized skills</Card.Header>
                                        <Card.Text className='m-process-box-flex-cs-card-text'>
                                            We can provide access to workers with specific skills or expertise that may not be available 
                                            in-house, such as IT professionals or specializedengineers.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-cs'>
                                <Card className='m-process-box-flex-cs-card'>
                                    <Card.Body className='m-process-box-flex-cs-card-body'>
                                        <Card.Header className='m-process-box-flex-cs-card-header'>Cost-effective & Time Saving</Card.Header>
                                        <Card.Text className='m-process-box-flex-cs-card-text'>
                                            Hiring contract workers through us can be more cost-effective as it eliminates 
                                            the need for the company to pay for benefits, taxes and other expenses associated 
                                            with permanent employees. And also saves time of initial screening and interviewing process.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-cs'>
                                <Card className='m-process-box-flex-cs-card'>
                                    <Card.Body className='m-process-box-flex-cs-card-body'>
                                        <Card.Header className='m-process-box-flex-cs-card-header'>Scalability</Card.Header>
                                        <Card.Text className='m-process-box-flex-cs-card-text'>
                                            You can scale your workforce up or down as needed by hiring more contract workers 
                                            during busy periods, and letting them go during slow periods.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-cs'>
                                <Card className='m-process-box-flex-cs-card'>
                                    <Card.Body className='m-process-box-flex-cs-card-body'>
                                        <Card.Header className='m-process-box-flex-cs-card-header'>Reduced Risk</Card.Header>
                                        <Card.Text className='m-process-box-flex-cs-card-text'>
                                            You can reduce risk by hiring contract workers through us. As we usually take 
                                            care of the legal and compliance issues associated with hiring contract workers.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-cs'>
                                <Card className='m-process-box-flex-cs-card'>
                                    <Card.Body className='m-process-box-flex-cs-card-body'>
                                        <Card.Header className='m-process-box-flex-cs-card-header'>Compliance and legal issues</Card.Header>
                                        <Card.Text className='m-process-box-flex-cs-card-text'>
                                            We are well-versed in compliance and legal issues related to hiring contract 
                                            workers and can ensure that a company's hiring process is in compliance with 
                                            relevant laws and regulations.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='m-know-more-link'>
                            <Link to='/contact-us/inquiry/contract-staffing'>
                                <button>Know More<FontAwesomeIcon className="m-know-more-arrow" icon={faLongArrowAltRight}/></button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className='contract-staffing-header-image'></div>
                    <div className='contract-staffing-main-container'>
                        <div className='contract-staffing-header'>
                            <h3>Contract Staffing</h3>
                        </div>
                        <div className='contract-staffing-content'>
                            <p>Tekscout offers contract staffing to help businesses find, hire, and manage temporary or
                                contract employees. Through our services you can bring flexibility and scale to your
                                operation / business conduct. All the candidate for consideration for a given role are
                                vetted and screened as per industry standards. We also give the option to you to have
                                rounds of discussion with the candidates before taking them onboard for any role.</p>
                            <div className="contract-staffing-box-1-question"><b>Why should you outsource your recruitment to tekscout? </b></div>
                            <div className='contract-staffing-process-box-flex'>
                                <div className='cs-arrow-div'>
                                    <span className='cs-arrow-span'>Flexibility</span>
                                </div>
                                <div className='contract-staffing-process-summary'>
                                    <p>Our Contract staffing service allows you to bring in workers for specific
                                    projects or periods of time, without committing to a long-term employment relationship.
                                    This will allow you to manage your workforce more flexibly, and respond to changes in
                                    demand or business conditions.</p>
                                </div>
                            </div>
                            <div className='contract-staffing-process-box-flex'>
                                <div className='cs-arrow-div'>
                                    <span className='cs-arrow-span'>Access to specialized skills</span>
                                </div>
                                <div className='contract-staffing-process-summary'>
                                    <p>We can provide access to workers with specific skills or expertise that may not be available 
                                    in-house, such as IT professionals or specializedengineers.</p>
                                </div>
                            </div>
                            <div className='contract-staffing-process-box-flex'>
                                <div className='cs-arrow-div'>
                                    <span className='cs-arrow-span'>Cost-effective & Time Saving</span>
                                </div>
                                <div className='contract-staffing-process-summary'>
                                    <p>Hiring contract workers through us can be more cost-effective as it eliminates 
                                    the need for the company to pay for benefits, taxes and other expenses associated 
                                    with permanent employees. And also saves time of initial screening and interviewing process.</p>
                                </div>
                            </div>
                            <div className='contract-staffing-process-box-flex'>
                                <div className='cs-arrow-div'>
                                    <span className='cs-arrow-span'>Scalability</span>
                                </div>
                                <div className='contract-staffing-process-summary'>
                                    <p>You can scale your workforce up or down as needed by hiring more contract workers 
                                        during busy periods, and letting them go during slow periods.</p>
                                </div>
                            </div>
                            <div className='contract-staffing-process-box-flex'>
                                <div className='cs-arrow-div'>
                                    <span className='cs-arrow-span'>Reduced Risk</span>
                                </div>
                                <div className='contract-staffing-process-summary'>
                                    <p>You can reduce risk by hiring contract workers through us. As we usually take 
                                        care of the legal and compliance issues associated with hiring contract workers.</p>
                                </div>
                            </div>
                            <div className='contract-staffing-process-box-flex'>
                                <div className='cs-arrow-div'>
                                    <span className='cs-arrow-span'>Compliance and legal issues</span>
                                </div>
                                <div className='contract-staffing-process-summary'>
                                    <p>We are well-versed in compliance and legal issues related to hiring contract workers 
                                        and can ensure that a company's hiring process is in compliance with relevant laws and regulations.</p>
                                </div>
                            </div>
                        </div>
                        <div className='know-more-link'>
                            <Link to='/contact-us/inquiry/contract-staffing'>
                                <button>Know More<FontAwesomeIcon className="m-know-more-arrow" icon={faLongArrowAltRight}/></button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }
        
    }
}

export default ContractStaffing;