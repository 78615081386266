import './PermanentStaffing.css';
import React, { Component } from 'react';
import CommonHeader  from '../../ComonHeader/CommonHeader';
import Header  from '../../Header/Header';
import Pricing from '../../PricingPlan/Pricing';
import { faDotCircle, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

class PermanentStaffing extends Component { 
    constructor(props) {
        super(props);
        this.state= {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        }
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className='m-perm-staffing-header-image'></div>
                    <div className='m-perm-staffing-main-container'>
                        <div className='m-perm-staffing-header'>
                            <h3>Permanent Staffing</h3>
                        </div>
                        <div className='m-perm-staffing-content'>
                        <p>Ensuring you hire the right permanent employees for your organization is a crucial step,
                                    and that's where we come in. At Tekscout, we are here to help you make the best selection.</p>
                            <p>Our permanent staffing solutions give you access to thoroughly vetted and capable
                                    candidates across any industry you desire. We have a dedicated team of consultants
                                    who are committed to supporting your company in achieving its goals. Our process is
                                    reliable and efficient, ensuring that we deliver the top candidates for any role in a timely
                                    manner.</p>
                            <div className='m-perm-staffing-box-1'>
                                <div className='m-perm-staffing-box-1-content'>
                                    <div className="m-perm-staffing-box-1-question"><b>Why should you outsource your recruitment to tekscout? </b></div>

                                    <div class="m-ps-sticky-notes-section">
                                        <div class="m-ps-sticky-notes m-ps-notes-1">
                                            <p>Access to larger pool of Candidates</p>
                                        </div>
                                        <div class="m-ps-sticky-notes m-ps-notes-2">
                                            <p>Recruitment not your core business</p>
                                        </div>
                                        <div class="m-ps-sticky-notes m-ps-notes-3">
                                            <p>Reduction in employee attrition</p>
                                        </div>
                                        <div class="m-ps-sticky-notes m-ps-notes-4">
                                            <p>Cost effective</p>
                                        </div>
                                        <div class="m-ps-sticky-notes m-ps-notes-5">
                                            <p>Time Saving</p>
                                        </div>
                                        <div class="m-ps-sticky-notes m-ps-notes-6">
                                            <p>Compliances & legal issues</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='m-srfc-model-image'></div>
                                <div className='m-go-to-pricing'>
                                    <Link to='/pricing'>
                                        <button>Explore our Pricing plans<FontAwesomeIcon className="go-to-pricing-arrow" icon={faLongArrowAltRight}/></button>
                                    </Link>
                                </div>
                            </div>
                            <div className='m-process-steps-container'>
                                <h4>
                                    <b>Our Process</b>
                                </h4>
                                <div className='m-process-box-flex'>
                                    <Card className='m-process-box-flex-card'>
                                        <Card.Body className='m-process-box-flex-card-body'>
                                            <Card.Header className='m-process-box-flex-card-header'>Understand your requirements</Card.Header>
                                            <Card.Text className='m-process-box-flex-card-text'>
                                                Understanding the client - you - is the first step in our process. We will spend
                                                time learning about your company, its long-term and short-term goals, its
                                                culture, and its history. We will also visit your workplace if necessary to gain a
                                                thorough understanding of the roles and responsibilities of the ideal candidate
                                                that you are seeking.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='m-process-box-flex'>
                                    <Card className='m-process-box-flex-card'>
                                        <Card.Body className='m-process-box-flex-card-body'>
                                            <Card.Header className='m-process-box-flex-card-header'>Defined Recruitment plan</Card.Header>
                                            <Card.Text className='m-process-box-flex-card-text'>
                                                Once you choose the best plan that suits your requirement. we will create a
                                                recruiting strategy that is unique to you. We have a large network of job
                                                seekers and talented candidates who are not actively looking for new
                                                opportunities but would be interested in a good offer. We also broaden our
                                                search by leveraging strategic alliances, personal networks, and contacts at
                                                civic and professional organisations.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='m-process-box-flex'>
                                    <Card className='m-process-box-flex-card'>
                                        <Card.Body className='m-process-box-flex-card-body'>
                                            <Card.Header className='m-process-box-flex-card-header'>Shortlisting and verification</Card.Header>
                                            <Card.Text className='m-process-box-flex-card-text'>
                                                Once we've identified a few candidates, we'll conduct tailored interviews to
                                                begin the shortlisting process. The best candidates will have both hard and soft
                                                skills required for the position. We choose the best candidates from this pool
                                                and send you their resumes. We collaborate with you to find the best candidate
                                                and finalise the job offer.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='m-process-box-flex'>
                                    <Card className='m-process-box-flex-card'>
                                        <Card.Body className='m-process-box-flex-card-body'>
                                            <Card.Header className='m-process-box-flex-card-header'>Closing the offer and feedback</Card.Header>
                                            <Card.Text className='m-process-box-flex-card-text'>
                                                We will also assist you with the final job offer, managing negotiations, and
                                                obtaining the candidate's signed offer letter. We also have an ongoing follow-
                                                up process in place where we collaborate with you and the candidate to ensure
                                                that the onboarding process goes smoothly.
                                            </Card.Text>
                                        </Card.Body>
                                        </Card>
                                </div>
                                <p>It is difficult to find dependable talent that meets your specific needs in today's
                                    dynamic market. Tekscout solves your staffing issue by sourcing and recruiting
                                    the best candidates who have the potential to become long-term assets to your company.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <Pricing/> */}
                </div>
            )
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className='perm-staffing-header-image'></div>
                    <div className='perm-staffing-main-container'>
                        <div className='perm-staffing-header'>
                            <h3>Permanent Staffing</h3>
                        </div>
                        <div className='perm-staffing-content'>
                            <p>Ensuring you hire the right permanent employees for your organization is a crucial step,
                                    and that's where we come in. At Tekscout, we are here to help you make the best selection.</p>
                            <p>Our permanent staffing solutions give you access to thoroughly vetted and capable
                                    candidates across any industry you desire. We have a dedicated team of consultants
                                    who are committed to supporting your company in achieving its goals. Our process is
                                    reliable and efficient, ensuring that we deliver the top candidates for any role in a timely
                                    manner.</p>
                            <div className='perm-staffing-box-1'>
                                <div className='perm-staffing-box-1-content'>
                                    <div className="perm-staffing-box-1-question"><b>Why should you outsource your recruitment to tekscout? </b></div>
                                    <div class="ps-sticky-notes-top ps-sticky-notes-section">
                                        <div class="ps-sticky-notes ps-sticky-notes-1-1">
                                            <p>Access to larger pool of Candidates</p>
                                        </div>
                                        <div class="ps-sticky-notes ps-sticky-notes-1-2">
                                            <p>Recruitment not your core business</p>
                                        </div>
                                        <div class="ps-sticky-notes ps-sticky-notes-1-3">
                                            <p>Reduction in employee attrition</p>
                                        </div>
                                    </div>
                                    <div class="ps-sticky-notes-section ps-sticky-notes-bottom">
                                        <div class="ps-sticky-notes ps-sticky-notes-2-1">
                                            <p>Cost effective</p>
                                        </div>
                                        <div class="ps-sticky-notes ps-sticky-notes-2-2">
                                            <p>Time Saving</p>
                                        </div>
                                        <div class="ps-sticky-notes ps-sticky-notes-2-3">
                                            <p>Compliances & legal issues</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='srfc-model-image'></div>
                                <div className='go-to-pricing'>
                                    <Link to='/pricing'>
                                        <button>Explore our Pricing plans<FontAwesomeIcon className="go-to-pricing-arrow" icon={faLongArrowAltRight}/></button>
                                    </Link>
                                </div>
                            </div>
                            
                            <div className='process-steps-container'>
                                <h4>
                                    <b>Our Process</b>
                                </h4>
                                <div className='ps-process-box-flex'>
                                    <div className='arrow-div'>
                                        <span className='arrow-span'>Understand your requirements</span>
                                    </div>
                                    <div className='ps-process-step-1'>
                                    <p>Understanding the client - you - is the first step in our process. We will spend
                                        time learning about your company, its long-term and short-term goals, its
                                        culture, and its history. We will also visit your workplace if necessary to gain a
                                        thorough understanding of the roles and responsibilities of the ideal candidate
                                        that you are seeking.</p>
                                    </div>
                                </div>
                                <div className='ps-process-box-flex'>
                                    <div className='arrow-div'>
                                        <span className='arrow-span'>Defined Recruitment plan</span>
                                    </div>
                                    <div className='ps-process-step-1'>
                                    <p>Once you choose the best plan that suits your requirement. we will create a
                                        recruiting strategy that is unique to you. We have a large network of job
                                        seekers and talented candidates who are not actively looking for new
                                        opportunities but would be interested in a good offer. We also broaden our
                                        search by leveraging strategic alliances, personal networks, and contacts at
                                        civic and professional organisations.</p>
                                    </div>
                                </div>
                                <div className='ps-process-box-flex'>
                                    <div className='arrow-div'>
                                        <span className='arrow-span'>Shortlisting and verification</span>
                                    </div>
                                    <div className='ps-process-step-1'>
                                    <p>Once we've identified a few candidates, we'll conduct tailored interviews to
                                        begin the shortlisting process. The best candidates will have both hard and soft
                                        skills required for the position. We choose the best candidates from this pool
                                        and send you their resumes. We collaborate with you to find the best candidate
                                        and finalise the job offer.</p>
                                    </div>
                                </div>
                                <div className='ps-process-box-flex'>
                                    <div className='arrow-div'>
                                        <span className='arrow-span'>Closing the offer and feedback</span>
                                    </div>
                                    <div className='ps-process-step-1'>
                                    <p>We will also assist you with the final job offer, managing negotiations, and
                                        obtaining the candidate&#39;s signed offer letter. We also have an ongoing follow-
                                        up process in place where we collaborate with you and the candidate to ensure
                                        that the onboarding process goes smoothly.</p>
                                    </div>
                                </div>
                                <p className='last-p-pricing'>It is difficult to find dependable talent that meets your specific needs in today's
                                    dynamic market. Tekscout solves your staffing issue by sourcing and recruiting
                                    the best candidates who have the potential to become long-term assets to your company.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            )
        }
    }
}

export default PermanentStaffing;