import './Homepage.css';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Header from '../Header/Header';
import CommonHeader from '../ComonHeader/CommonHeader';

class Homepage extends Component { 
    constructor(props) {
        super(props);
        this.state= {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        }
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className="m-landing-page">
                        <section className='pricing-part pricing-1'>
                            <div className='m-pricing-left-1' >
                                <h1>Facing Challenges in identifying the perfect candidate for your organization?</h1>
                                <p> Our process enables us to manage recruitment related activities and become native partners for 
                                    companies focused on working in their core areas of expertise.
                                </p>
                                <Link to='/permanent-staffing'><button className="m-homepage-button pricing-section-1-button">Know More</button></Link>
                            </div>
                        </section>
                        <section className='pricing-part pricing-2'>
                            <div className="m-pricing-left-2"> 
                                <h1>Seeking talented and skilled professionals for contact based role?</h1>
                                    <p>We enable businesses to find, hire and manage temporary or contract
                                        employees to bring flexibility and scale their operation or business conduct.</p>
                                        <Link to='/contract-staffing'><button className="m-homepage-button pricing-section-2-button">Know More</button></Link>
                            </div>
                        </section>
                        
                        {/* <section className='m-howWeDoIt'>
                            <h1>We make it easy for you</h1>
                            <img src={SRFSC_Model_TS} alt="stateDiagram" className='m-imageCoverSize'/>
                        </section> */}
                        <section className='pricing-part pricing-3'>
                        <div className="m-pricing-left-3"> 
                                <h1>Revolutionizing Recruitment with Subscription-Based Services.</h1>
                                    <p>Recruitment As – A Service: Timely, affordable and qualified recruitment
                                        specialists working dedicatedly for your company which will enable you to
                                        build competitive capability faster and more effectively.</p>
                                        <Link to='/recruiter-service'><button className="m-homepage-button pricing-section-3-button">Know More</button></Link>
                            </div>
                        </section>
                        <section className='pricing-part pricing-4'>
                        <div className="m-pricing-left-4"> 
                                <h1>Overcome the challenge of hiring the Perfect Candidate.</h1>
                                    <p>Along with our sincere efforts to understand the requirement, our advance
                                        search framework rightly enables you to find the perfect candidate. All the
                                        candidate will be Pre-evaluated and TS verified (tekscout verified)</p>
                                        <Link to='/executive-search'><button className="m-homepage-button pricing-section-4-button">Know More</button></Link>
                            </div>
                        </section>
                        <section className='about-us-home'>
                            <div className='m-aboutUs-home-left'>
                                <h1>About Us</h1>
                                <p>TS is committed to offering our clients value-added services. 
                                    We strive to serve companies ranging from start-ups to medium-sized
                                        businesses at all life stages. 
                                        We have experience in a variety of industries, 
                                        including e-commerce and IT </p>
                                        <Link to='/about-us'><button className="m-homepage-button about-us-button">Know More</button></Link>
                            </div>
                        </section>
                    </div>
                </div>
            );
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className="landing-page">
                        <section className='pricing-part pricing-1'>
                            <div className='pricing-left-1' >
                                <h1>Facing Challenges in identifying the perfect candidate for your organization?</h1>
                                <p> Our process enables us to manage recruitment related activities and become native partners for 
                                    companies focused on working in their core areas of expertise.
                                    </p>
                                <Link class="know-more-padding"  to='/permanent-staffing'><button className="homepage-button pricing-section-1-button">Know More</button></Link>
                            </div>
                            <div className="image-width">
                            {/* <img src={PermanentStaffing} className='image-1-size'/> */}
                            </div>
                        </section>
                        <section className='pricing-part pricing-2'>
                            <div className="image-width-2">
                                {/* <img src={ContractStaffing} className='image-1-size'/> */}
                            </div>
                            <div className="pricing-left-2"> 
                                <h1>Seeking talented and skilled professionals for contact based role?</h1>
                                    <p>We enable businesses to find, hire and manage temporary or contract
                                        employees to bring flexibility and scale their operation or business conduct.</p>
                                <Link class="know-more-padding"  to='/contract-staffing'><button className="homepage-button pricing-section-2-button">Know More</button></Link>
                            </div>
                        </section>
                        <section className='pricing-part pricing-3'>
                        <div className="pricing-left-3"> 
                                <h1>Revolutionizing Recruitment with Subscription-Based Services.</h1>
                                    <p>Recruitment As – A Service: Timely, affordable and qualified recruitment
                                        specialists working dedicatedly for your company which will enable you to
                                        build competitive capability faster and more effectively.</p>
                                    <Link class="know-more-padding"  to='/recruiter-service'><button className="homepage-button pricing-section-3-button">Know More</button></Link>
                            </div>
                            <div className="image-width-3">
                                {/* <img src={RAAS} className='image-1-size'/> */}
                            </div>
                        </section>
                        <section className='pricing-part pricing-4'>
                            <div className="image-width-4">
                                {/* <img src={ExecutiveSearch} className='image-1-size'/> */}
                            </div>
                            <div className="pricing-left-4"> 
                                <h1>Overcome the challenge of hiring the Perfect Candidate.</h1>
                                    <p>Along with our sincere efforts to understand the requirement, our advanced
                                        search framework rightly enables you to find the perfect candidate. All the
                                        candidate will be pre-evaluated and TS verified (tekscout verified)</p>
                                <Link class="know-more-padding" to='/executive-search'><button className="homepage-button pricing-section-4-button">Know More</button></Link>
                            </div>
                        </section>
                        <section className='about-us-home'>
                            <div className='aboutUs-home-left'>
                                <h1>About Us</h1>
                                <p>TS is committed to offering our clients value-added services. 
                                    We strive to serve companies ranging from start-ups to medium-sized
                                        businesses at all life stages. 
                                        We have experience in a variety of industries, 
                                        including e-commerce and IT </p>
                                        <Link class="know-more-padding"  to='/about-us'><button className="homepage-button about-us-button">Know More</button></Link>
                            </div>
                            <div className="image-width-5">
                                {/* <img src={AboutUs} className='image-1-size'/> */}
                            </div>
                        </section>
                    </div>
                </div>
            );
        }
        
    }
}
export default Homepage;
