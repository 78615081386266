import './ContactUs.css';
import React, { Component } from 'react';
import CommonHeader  from '../ComonHeader/CommonHeader';
import { Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import withRouter from '../withRouter';
import Header  from '../Header/Header';

class ContactUs extends Component { 
    
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            fullName: '',
            email: '',
            phNum: '',
            details: '',
            plan: '',
            service: this.props.params.type !== 'inquiry' ? 'Permanent Staffing' : this.props.params.page,
            permStaffSelected: '',
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false,
            emailSent: false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        let fieldName = event.target.name;
        if(fieldName === 'phNum'){
            let phNumInput = event.target.value.replace(/\D/g, "");
            this.setState({[fieldName]: phNumInput});
        } else {
            this.setState({[fieldName]: event.target.value});
        }
        //this.setState({[fieldName]: event.target.value});
        this.checkInputs();
    }
    handleSubmit(e) {
        e.preventDefault();
        emailjs.sendForm(
            'service_656gfsg',      //'service_3bm1kp5', //Service_id
            'template_l1775rc',     //'template_wd7c1vn', //Template_id
             this.form.current, 
             '1spQJFICXtcLIJKSi')    //'_vavMPRbDV9q8gksy') //Public_key
        .then((result) => {
            console.log(result.text);
            if(result.text === 'OK') {
                this.setState({emailSent : true});
                setTimeout(() => {
                    this.setState({emailSent : false});
                }, 5000);
            }
        }, (error) => {
            console.log(error.text);
        });
        this.clearForm();
    }
    clearForm() {
        this.setState({
            fullName:'',
            email: '',
            phNum: '',
            details: '',
            plan: '',
            service: ''
        });
    }
    checkInputs() {
        if(this.state.fullName !== '' && this.state.email !== '' && this.state.phNum !== '')
            return false;
        else
            return true;
    }
    render() {
        let serviceDropdown, planDropdown, emailSentMessage;
        const pricingOptions = [
            {
                label: "Select a plan",
                value: "",
              },
            {
              label: "Basic",
              value: "basic",
            },
            {
              label: "Start Up",
              value: "start-up",
            },
            {
              label: "Rising Star",
              value: "rising-star",
            },
            {
              label: "Big Shot",
              value: "big-shot",
            },
            {
                label: "Enterprise",
                value: "enterprise",
            },
            {
                label: "RPO",
                value: "rpo",
            }
        ];
        if(this.state.emailSent) {
            emailSentMessage = <div className='email-sent-message'>
                Scouting team will contact you soon.
            </div>
        }
        if(this.state.service === 'Permanent Staffing'){
            planDropdown= <div>
                <Form.Select aria-label="Pricing" name='plan' className='m-form-select' onChange={this.handleChange} defaultValue= {this.props.params.type}>
                    {pricingOptions.map((option) => (
                        <option value={option.value} key={option.value+"-key"} >{option.label}</option>
                    ))}
                </Form.Select>
            </div>;
        } 
        serviceDropdown = <div>
            <div>
                <Form.Select aria-label="Services List" name="service" className='m-form-select' onChange={this.handleChange} defaultValue={this.state.service}>
                    <option value=''>Select service type</option>
                    <option value="Permanent Staffing">Permanent Staffing</option>
                    <option value="recruiter-service">Recruiter as a Service</option>
                    <option value="executive-search">Executive Search</option>
                    <option value="Job-Search">Find a Job</option>
                    <option value="Query">Ask a Question</option>
                    <option value="contract-staffing">Contract Staffing</option>
                </Form.Select> 
            </div>
            {planDropdown}
        </div>;
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className='m-contact-page-main-container'>
                        <div className="m-contact-page-banner">
                            <h1>We'd love to hear from you</h1>
                            <p>Have questions about our services, pricing? Our scouting team is all set to help you.</p>
                        </div>
                        <div className='m-contact-page-content'>
                            <div className='m-contact-left'> 
                                <div>
                                    <div className='locations-body'> 
                                        <h1>Office locations</h1>
                                        <div className='address'>
                                            <div className='bbsr-location'>
                                                <div className='bbsr-location-image m-bbsr-img'></div>
                                                <div className='m-location-address'>
                                                    <b>Bhubaneswar, Odisha</b><br/>
                                                    <div>C-3 Lingaraj Vihar, Pokhariput<br/> Bhubaneswar, Odisha<br/> 751020</div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className='address'>
                                            <div className='amvd-location'>
                                                <div className='amvd-location-image m-amvd-img'></div>
                                                <div className='m-location-address'>
                                                    <b>Ahmedabad, Gujarat</b><br/>
                                                    <div>3 - Tankar Residency<br/> Ahmedabad, Gujarat<br/> 382440</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='m-contact-right'> 
                                <div className='contact-form-body'> 
                                    <h1>Get in touch with us</h1>
                                    <h6>{emailSentMessage}</h6>
                                    <form className='contact-form' id="contact-form" 
                                        onSubmit={this.handleSubmit.bind(this)} method="POST" ref={this.form}>
                                            <div>
                                                <input type="text" placeholder='Enter your name here' name='fullName'
                                                value={this.state.fullName} onChange={this.handleChange} /> 
                                            </div>
                                            <div><input type="email" placeholder='johndoe@abc.com' name='email'
                                                value={this.state.email} onChange={this.handleChange}/></div>
                                            
                                            {serviceDropdown}
    
                                            <div><input type="text" placeholder='Phone Number' name='phNum' maxLength={10}
                                                value={this.state.phNum} onChange={this.handleChange}/></div>
                                            <div><textarea placeholder='More details' name='details'
                                                value={this.state.details} onChange={this.handleChange}></textarea></div>
                                            <div className='m-send-message'><button type="submit" 
                                            disabled={!(this.state.fullName !== '' && this.state.email !== '' && this.state.phNum !== '')}>Submit</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className='contact-page-main-container'>
                        <div className="contact-page-banner">
                            <h1>We'd love to hear from you</h1>
                            <p>Have questions about our services, pricing? Our scouting team is all set to help you.</p>
                        </div>
                        <div className='contact-page-content'>
                            <div className='contact-left'> 
                                <div>
                                    <div className='locations-body'> 
                                        <h1>Office locations</h1>
                                        <div className='address'>
                                            <div className='bbsr-location'>
                                                <div className='bbsr-location-image'></div>
                                                <div className='location-address'>
                                                    <b>Bhubaneswar, Odisha</b><br/>
                                                    <div>C-3 Lingaraj Vihar, Pokhariput<br/> Bhubaneswar, Odisha<br/> 751020</div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className='address'>
                                            <div className='amvd-location'>
                                                <div className='amvd-location-image'></div>
                                                <div className='location-address'>
                                                    <b>Ahmedabad, Gujarat</b><br/>
                                                    <div>3 - Tankar Residency<br/> Ahmedabad, Gujarat<br/> 382440</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='contact-right'> 
                            <div className='contact-form-body'> 
                                    <h1>Get in touch with us</h1>
                                    {emailSentMessage}
                                    <form className='contact-form' id="contact-form" 
                                        onSubmit={this.handleSubmit.bind(this)} method="POST" ref={this.form}>
                                            <div>
                                                <input type="text" placeholder='Enter your name here' name='fullName'
                                                value={this.state.fullName} onChange={this.handleChange} /> 
                                            </div>
                                            <div><input type="email" placeholder='johndoe@abc.com' name='email'
                                                value={this.state.email} onChange={this.handleChange}/></div>
                                            
                                            {serviceDropdown}
    
                                            <div><input type="text" placeholder='Phone Number' name='phNum' maxLength={10}
                                                value={this.state.phNum} onChange={this.handleChange}/></div>
                                            <div><textarea placeholder='More details' name='details'
                                                value={this.state.details} onChange={this.handleChange}></textarea></div>
                                            <div className='send-message'><button type="submit" 
                                            disabled={!(this.state.fullName !== '' && this.state.email !== '' && this.state.phNum !== '')}>Submit</button></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
    }
}

export default withRouter(ContactUs);