import './Header.css';
import TSlogoWhite from '../../assets/TS_logo_white.png';
import mTSlogoWhite from '../../assets/m_TS_logo_white.png';
import Navbar from 'react-bootstrap/Navbar';
import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageSrc: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false ) ? mTSlogoWhite: TSlogoWhite,
            imageAlt: '',
            menuColor: '#FFF',
            show: false,
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        };
    }
    
    listenScrollEvent = event => {
        this.setState({
        imageSrc: TSlogoWhite,
        imageAlt: 'smartlogger black logo',
        menuColor: '#FFF'
        });
    }

    handleClose = () => {
        this.setState({show : false});
    }

    handleShow = () => {
        this.setState({show : true});
    }
    
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        window.addEventListener('scroll', this.listenScrollEvent);
    }
    componentWillUnmount() {
        window.onscroll = null;
    }
    render(){
        if(this.state.isMobile) {
            return(
                <div className="m-nav-bar-main-container">
                    <div className="m-nav-bar-left-container">
                        <Navbar sticky="top">
                            <Navbar.Brand href="../.." className="m-tekscout-brand-logo">
                                <img src={this.state.imageSrc} className="m-tekscout-logo-main" alt="tekscoutLogo"></img>                               
                            </Navbar.Brand>
                            <Navbar.Text className='m-menu-bar'>
                                <FontAwesomeIcon icon={faBars} style={{color:this.state.menuColor}} onClick={this.handleShow}/>
                            </Navbar.Text>
                            <Offcanvas show={this.state.show} onHide={this.handleClose} placement="end" autoFocus="true" className="m-menu">
                                <Offcanvas.Header closeButton className="m-slider-menu-header">
                                <Offcanvas.Title >Menu</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className='m-slider-menu'>
                                        <div><Link to='/permanent-staffing'>Permanent Staffing</Link></div>
                                        <div><Link to='/contract-staffing'>Contract Staffing</Link></div>
                                        <div><Link to='/recruiter-service'>Recruiter as a Service</Link></div>
                                        <div><Link to='/executive-search'>Executive Search</Link></div>
                                        {/* <div><Link to='/pricing'>Pricing</Link></div> */}
                                        <div><a target={"_blank"} rel="noreferrer" href='https://tekscout.springrecruit.com/careers'>Jobs</a></div>                                        
                                        <div><Link to='/about-us'>About Us</Link></div>
                                        <div><Link to='/contact-us/inquiry/inquiry'>Contact Us</Link></div>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </Navbar>
                    </div>
                </div>
            );
        } else {
            return(
                <div className="nav-bar-main-container">
                    <div className="nav-bar-left-container">
                        <Navbar sticky="top">
                            <Navbar.Brand href=".." className="tekscout-brand-logo">
                                <img src={this.state.imageSrc} className="tekscout-logo-main" alt="tekscoutLogo"></img>                               
                            </Navbar.Brand>
                            <Navbar.Text>
                                <FontAwesomeIcon icon={faBars} style={{color:this.state.menuColor}} onClick={this.handleShow}/>
                            </Navbar.Text>
                            <Offcanvas show={this.state.show} onHide={this.handleClose} placement="end" autoFocus="true">
                                <Offcanvas.Header closeButton className="slider-menu-header">
                                <Offcanvas.Title >Menu</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <div className='slider-menu'>
                                        <div><Link to='/permanent-staffing'>Permanent Staffing</Link></div>
                                        <div><Link to='/contract-staffing'>Contract Staffing</Link></div>
                                        <div><Link to='/recruiter-service'>Recruiter as a Service</Link></div>
                                        <div><Link to='/executive-search'>Executive Search</Link></div>
                                        <div><Link to='/pricing'>Pricing</Link></div>
                                        <div><a target={"_blank"} rel="noreferrer" href='https://tekscout.springrecruit.com/careers'>Jobs</a></div>
                                        <div><Link to='/about-us'>About Us</Link></div>
                                        <div><Link to='/contact-us/inquiry/inquiry'>Contact Us</Link></div>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        </Navbar>
                    </div>
                </div>
            );
        }
            
    }
}

export default Header;