import './RecruiterService.css';
import React, { Component } from 'react';
import CommonHeader  from '../../ComonHeader/CommonHeader';
import Header  from '../../Header/Header';
import Card from 'react-bootstrap/Card';
import { faDotCircle, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

class RecruiterService extends Component { 
    constructor(props) {
        super(props);
        this.state= {
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        }
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className='m-rs-header-image'></div>
                    <div className='m-rec-service-main-container'>
                        <div className='m-perm-staffing-header'>
                            <h3>Recruiter as a Service (RaaS)</h3>
                        </div>
                        <div className='m-rec-service-content'>
                            <p>The most significant benefit of choosing Professional RPO is the cost-cutting factor associated with our services.</p>

                            <div className='m-process-box-flex-rs'>
                                <Card className='m-process-box-flex-rs-card'>
                                    <Card.Body className='m-process-box-flex-rs-card-body'>
                                        <Card.Header className='m-process-box-flex-rs-card-header'>Quality</Card.Header>
                                        <Card.Text className='m-process-box-flex-rs-card-text'>
                                            All procedures and products created for your project are examined by our internal 
                                            quality control team. To summarize, we provide expert assistance in securing a 
                                            diverse talent pool at all stages of the recruitment process.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-rs'>
                                <Card className='m-process-box-flex-rs-card'>
                                    <Card.Body className='m-process-box-flex-rs-card-body'>
                                        <Card.Header className='m-process-box-flex-rs-card-header'>Taking away the administrative burden</Card.Header>
                                        <Card.Text className='m-process-box-flex-rs-card-text'>
                                            We relieve organizations of the burden of operational recruitment activities, 
                                            allowing them to focus on value-creating and strategic activities.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-rs'>
                                <Card className='m-process-box-flex-rs-card'>
                                    <Card.Body className='m-process-box-flex-rs-card-body'>
                                        <Card.Header className='m-process-box-flex-rs-card-header'>Cost</Card.Header>
                                        <Card.Text className='m-process-box-flex-rs-card-text'>
                                            SME Resourcing can free you up to focus on core business by managing your end-to-end process. 
                                            Cost-cutting measures can be implemented in the recruiting zone as our specialists 
                                            work to execute the outsourced processes.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-rs'>
                                <Card className='m-process-box-flex-rs-card'>
                                    <Card.Body className='m-process-box-flex-rs-card-body'>
                                        <Card.Header className='m-process-box-flex-rs-card-header'>Reliability</Card.Header>
                                        <Card.Text className='m-process-box-flex-rs-card-text'>
                                        When you outsource your recruitment needs to Professional RPO, you gain immediate access 
                                        to high-quality RPO services delivered by qualified recruitment specialists. 
                                        We use back-up researchers who are well-versed in your account. As a result, 
                                        your work is not hampered, and the necessary processes run smoothly and without delay. 
                                        Processes are constantlymonitored and measured to ensure that higher-quality services are delivered.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='m-process-box-flex-rs'>
                                <Card className='m-process-box-flex-rs-card'>
                                    <Card.Body className='m-process-box-flex-rs-card-body'>
                                        <Card.Header className='m-process-box-flex-rs-card-header'>Productivity</Card.Header>
                                        <Card.Text className='m-process-box-flex-rs-card-text'>
                                            Every candidate is captured by our technology, and all communication with them is automated. 
                                            We are constantly on the lookout for new processes and techniques that can add value to your 
                                            recruitment process. Our research team identifies and analyses requirements.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='rect-serv-box-1'>
                                <div className='rect-serv-box-1-content'>
                                    <div className="m-rect-serv-box-1-question"><b>Why should you opt for RAAS (Recruiter as – a Service) from tekscout?</b></div>
                                    <div class="m-ps-sticky-notes-section">
                                        <div class="m-rs-sticky-notes m-ps-notes-1">
                                            <p>Access to large candidate network</p>
                                        </div>
                                        <div class="m-rs-sticky-notes m-ps-notes-2">
                                            <p> Saving – Time, Effort & Resource</p>
                                        </div>
                                        <div class="m-rs-sticky-notes m-ps-notes-3">
                                            <p> Access to Experts / Expertise</p>
                                        </div>
                                        <div class="m-rs-sticky-notes m-rs-notes-4">
                                            <p>Dedicated recruiter</p>
                                        </div>
                                        <div class="m-rs-sticky-notes m-ps-notes-5">
                                            <p>Unmatched Flexibility</p>
                                        </div>
                                        <div class="m-ps-sticky-notes m-ps-notes-6">
                                            <p>Improved hiring process</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Timely, affordable and qualified recruitment specialists working dedicatedly for your company enable you to 
                                build competitive capability faster and more effectively. Relying on our years of accumulated experience, we 
                                have developed the best practices for all research and recruitment oriented tasks, including internet research and database management.</p>
                        </div>
                        <div className='m-know-more-link'>
                            <Link to='/contact-us/inquiry/recruiter-service'>
                                <button>Know More<FontAwesomeIcon className="m-know-more-arrow" icon={faLongArrowAltRight}/></button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className='rs-header-image'></div>
                    <div className='rec-service-main-container'>
                        <div className='rec-service-header'>
                            <h3>Recruiter as a Service (RaaS)</h3>
                        </div>
                        <div className='rec-service-content'>
                            <p>The most significant benefit of choosing Professional RPO is the cost-cutting factor associated with our services.</p>
                            <div className='rect-serv-box-1'>
                                <div className='rect-serv-box-1-content'>
                                    <div className="rect-serv-box-1-question"><b>Why should you opt for RAAS (Recruiter as – a Service) from tekscout?</b></div>
                                    <div class="sticky-notes-top sticky-notes-section">
                                        <div class="rs-sticky-notes-1 rs-sticky-notes-1-1">
                                            <p>Access to large candidate network</p>
                                        </div>
                                        <div class="rs-sticky-notes-1 rs-sticky-notes-1-2">
                                            <p> Saving – Time, Effort & Resource</p>
                                        </div>
                                        <div class="rs-sticky-notes-1 rs-sticky-notes-1-3">
                                            <p> Access to Experts / Expertise</p>
                                        </div>
                                        <div class="rs-sticky-notes-1 rs-sticky-notes-1-4">
                                            <p>Advanced Technology</p>
                                        </div>
                                    </div>
                                    <div class="sticky-notes-section rs-sticky-notes-bottom">
                                        <div class="rs-sticky-notes rs-sticky-notes-2-1">
                                            <p>Dedicated recruiter</p>
                                        </div>
                                        <div class="rs-sticky-notes rs-sticky-notes-2-2">
                                            <p>Unmatched Flexibility</p>
                                        </div>
                                        <div class="rs-sticky-notes rs-sticky-notes-2-3">
                                            <p>Improved hiring process</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="rs-p-single">Timely, affordable and qualified recruitment specialists working dedicatedly for your company enable you to 
                                build competitive capability faster and more effectively. Relying on our years of accumulated experience, we 
                                have developed the best practices for all research and recruitment oriented tasks, including internet research and database management.</p>
                            
                            <div className='rs-process-box-flex'>
                                <div className='rs-arrow-div'>
                                    <span className='rs-arrow-span'>Quality</span>
                                </div>
                                <div className='rs-process-summary'>
                                    <p>All procedures and products created for your project are examined by our internal quality 
                                        control team. To summarize, we provide expert assistance in securing a diverse talent pool 
                                        at all stages of the recruitment process.</p>
                                </div>
                            </div>
                            <div className='rs-process-box-flex'>
                                <div className='rs-arrow-div'>
                                    <span className='rs-arrow-span'>Taking away <br/>the administrative burden</span>
                                </div>
                                <div className='rs-process-summary'>
                                    <p>We relieve organizations of the burden of operational recruitment activities, allowing them 
                                        to focus on value-creating and strategic activities.</p>
                                </div>
                            </div>
                            <div className='rs-process-box-flex'>
                                <div className='rs-arrow-div'>
                                    <span className='rs-arrow-span'>Cost</span>
                                </div>
                                <div className='rs-process-summary'>
                                    <p>SME Resourcing can free you up to focus on core business by managing your end-to-end process. 
                                        Cost-cutting measures can be implemented in the recruiting zone as our specialists work to 
                                        execute the outsourced processes using the best research and recruitment practices 
                                        lowering recruitment costs.</p>
                                </div>
                            </div>
                            <div className='rs-process-box-flex'>
                                <div className='rs-arrow-div'>
                                    <span className='rs-arrow-span'>Reliability</span>
                                </div>
                                <div className='rs-process-summary'>
                                    <p>When you outsource your recruitment needs to Professional RPO, you gain immediate access to 
                                        high-quality RPO services delivered by qualified recruitment specialists. We use back-up 
                                        researchers who are well-versed in your account. As a result, your work is not hampered, 
                                        and the necessary processes run smoothly and without delay. Processes are constantly 
                                        monitored and measured to ensure that higher-quality services are delivered</p>
                                </div>
                            </div>
                            <div className='rs-process-box-flex'>
                                <div className='rs-arrow-div'>
                                    <span className='rs-arrow-span'>Productivity</span>
                                </div>
                                <div className='rs-process-summary'>
                                    <p>Every candidate is captured by our technology, and all communication with them is automated. 
                                        We are constantly on the lookout for new processes and techniques that can add 
                                        value to your recruitment process. Our research team identifies and analyses requirements.</p>
                                </div>
                            </div>
                            </div>
                        <div className='know-more-link'>
                            <Link to='/contact-us/inquiry/recruiter-service'>
                                <button>Know More<FontAwesomeIcon className="m-know-more-arrow" icon={faLongArrowAltRight}/></button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default RecruiterService;