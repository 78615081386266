import './Pricing.css';
import React, { Component } from 'react';
import CommonHeader  from '../ComonHeader/CommonHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle, faDotCircle} from '@fortawesome/free-solid-svg-icons';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import Header  from '../Header/Header';

class Pricing extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            showDetails: true,
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        };
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className='m-pricing-page-main-container'>
                        {/* <div className='m-pricing-page-header'>
                            <h2>Pricing</h2>
                        </div> */}
                        <div className='m-pricing-page-content'>
                            
                                <p className='m-pricing-tag-line'>Scouting <br/>
                                <s>Hiring</s> does not have to be Expensive</p>
                        </div>
                        {/* <h6><b>Basic</b></h6>
                                        <p className='plan-desc'>For companies who want to test waters</p>
                                        <p class="banner basic-banner">TS Verified</p>
                                        <h2><b>1</b></h2>
                                        <p className='resource-count'>resource</p>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  49,000</b></p>
                                        <Link to='/contact-us/basic'><button>Know More</button></Link> */}
                        <div className='m-pricing-plans'>
                            <div className='pricing-plans-row1'>
                                <div className='m-pricing-box'>
                                    <div className='pricing-content m-pricing-content'>
                                        <h6><b>Basic</b></h6>
                                        {/* <p className='plan-desc'>For companies who want to test waters</p> */}
                                        <p class="banner m-basic-banner">TS Verified</p>
                                        <h2><b>1</b></h2>
                                        <p className='resource-count'>resource</p>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  49,000</b></p>
                                        <Link to='/contact-us/inquiry/basic'><button>Know More</button></Link>
                                    </div>
                                </div>
                                <div className='m-pricing-box'>
                                    <div className='pricing-content m-pricing-content'>
                                        <h6><b>Start Up</b></h6>
                                        {/* <p className='plan-desc'>For companies with small project</p> */}
                                        <p class="banner m-basic-banner">TS Verified</p>
                                        <h2><b>3</b></h2>
                                        <p className='resource-count'>resources</p>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  1,39,000</b></p>
                                        <Link to='/contact-us/inquiry/start-up'><button>Know More</button></Link>
                                    </div>
                                </div>
                                <div className='m-pricing-box'>
                                    <div className='pricing-content m-pricing-content'>
                                        <h6><b>Rising Star</b></h6>
                                        {/* <p className='plan-desc'>Continuous onboarding requirement</p> */}
                                        <p class="banner m-basic-banner">TS Verified</p>
                                        <h2><b>7</b></h2>
                                        <p className='resource-count'>resources</p>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  2,99,000</b></p>
                                        <Link to='/contact-us/inquiry/rising-star'><button>Know More</button></Link>
                                    </div>
                                </div>
                                <div className='m-pricing-box'>
                                    <div className='pricing-content m-pricing-content'>
                                        <h6><b>Big Shot</b></h6>
                                        {/* <p className='plan-desc'>For companies who are well settled</p> */}
                                        <p class="banner m-basic-banner">TS Verified</p>
                                        <h2><b>15</b></h2>
                                        <p className='resource-count'>resources</p>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  5,99,000</b></p>
                                        <Link to='/contact-us/inquiry/big-shot'><button>Know More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pricing-plans'>
                            <div className='pricing-plans-row2'>
                                <div className='m-pricing-box-row2'>
                                    <div className='pricing-content'>
                                        <h6><b>Enterprise</b></h6>
                                        <p className='plan-desc plan-summary-row2'>For companies whose requirements are big</p>
                                        <p class="banner m-basic-banner">TS Verified</p>
                                        <div className='pricing-conditions m-pc m-pc-row2'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <Link to='/contact-us/inquiry/enterprise'><button className='plan-desc-row2'>Know More</button></Link>
                                    </div>
                                </div>
                                {/* <div className='m-pricing-box-row2'>
                                    <div className='pricing-content'>
                                        <h5>RPO</h5>
                                        <p className='plan-desc plan-summary-row2'>For companies who want to hire recruiter on monthly basis </p>
                                        <Link to='/contact-us/rpo'><button className='plan-desc-row2'>Customize Now</button></Link>
                                    </div>
                                </div> */}
                            </div>
                            
                        </div>
                        <div className='pricing-page-content-2 m-pricing-page-content'>
                            {/* <p>For enterprise deals, click on the <Link to='/contact-us/enterprise'>Link</Link> to get customised pricing.</p> */}
                            {/* <p>Our SRSFC Model is driven by the SPEEED delivery framework with multi-layer Feedback mechanism.
                                Through this process we intend to become your native partner by helping and assisting you in
                                recruitment activities so that you can focus on your core expertise &amp; skills to achieve milestones.
                                With lot of honesty we position ourselves as supporting partners.</p>
                            <p>All these sounds like very costly affair - for which we have taken every possible step to reduce the
                                burden from you. Our “you grow – we grow” model will help us to deliver the taken responsibility as per
                                your expectation but in an ultra-cost effective mode. Select from the best plan that suits your need and
                                sit back to experience our service delivery.</p> */}
                            <div className='m-ts-verified-list-div'>
                                <p>* All TS verified candidates: </p>
                                <p>Dedicated Recruiter | Committed TAT | Interview Assistance | Validate Resumes - TS verified | Pyschometric Test | Feedback metrics </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className='pricing-page-main-container'>
                        <div className='pricing-page-header'>
                        </div>
                        <div className='pricing-page-content'>
                            
                                <p className='pricing-tag-line'>Scouting <br/>
                                <s>Hiring</s> does not have to be Expensive</p>
                        </div>
                        
                        <div className='pricing-plans'>
                            <div className='pricing-plans-row1'>
                                <div className='pricing-box'>
                                    <div className='pricing-content'>
                                        <h6><b>Basic</b></h6>
                                        {/* <p className='plan-desc'>For companies who want to test waters</p> */}
                                        <p class="banner basic-banner">TS Verified</p>
                                        <h2><b>1</b></h2>
                                        <p className='resource-count'>position</p>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  49,000</b></p>
                                        <Link to='/contact-us/inquiry/basic'><button>Know More</button></Link>
                                    </div>
                                </div>
                                <div className='pricing-box'>
                                    <div className='pricing-content'>
                                        <h6><b>Start Up</b></h6>
                                        {/* <p className='plan-desc'>For companies with small project</p> */}
                                        <p class="banner">TS Verified</p>
                                        <h2><b>3</b></h2>
                                        <p className='resource-count'>positions</p>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  1,39,000</b></p>
                                        <Link to='/contact-us/inquiry/start-up'><button>Know More</button></Link>
                                    </div>
                                </div>
                                <div className='pricing-box'>
                                    <div className='pricing-content'>
                                        <h6><b>Rising Star</b></h6>
                                        {/* <p className='plan-desc'>Continuous onboarding requirement</p> */}
                                        <p class="banner">TS Verified</p>
                                        <h2><b>7</b></h2>
                                        <p className='resource-count'>positions</p>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  2,99,000</b></p>
                                        <Link to='/contact-us/inquiry/rising-star'><button>Know More</button></Link>
                                    </div>
                                </div>
                                <div className='pricing-box'>
                                    <div className='pricing-content'>
                                        <h6><b>Big Shot</b></h6>
                                        {/* <p className='plan-desc'>For companies who are well settled</p> */}
                                        <p class="banner">TS Verified</p>
                                        <h2><b>15</b></h2>
                                        <p className='resource-count'>positions</p>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        <p className='price-starts'>Starts @  <b>INR  5,99,000</b></p>
                                        <Link to='/contact-us/inquiry/big-shot'><button>Know More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pricing-plans'>
                            <div className='pricing-plans-row2'>
                                <div className='pricing-box-row2'>
                                    <div className='pricing-content'>
                                        <h6><b>Enterprise</b></h6>
                                        <p className='plan-desc plan-summary-row2'>For companies whose requirements are big</p>
                                        <p class="banner">TS Verified</p>
                                        <div className='pricing-conditions-row2'>
                                            <div className='pricing-conditions-point'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                            <div className='pricing-conditions-point'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div>
                                        </div>
                                        {/* <p class="banner m-basic-banner">TS Verified</p> */}
                                        {/* <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>2 months replacement<sup>*</sup></div>
                                        <div className='pricing-conditions m-pc'><FontAwesomeIcon className="bullet-point-fa-icon" icon={faDotCircle}/>15 days TAT<sup>**</sup></div> */}
                                        <Link to='/contact-us/inquiry/enterprise'><button className='plan-desc-row2'>Know More</button></Link>
                                    </div>
                                </div>
                                {/* <div className='m-pricing-box-row2'>
                                    <div className='pricing-content'>
                                        <h5>RPO</h5>
                                        <p className='plan-desc plan-summary-row2'>For companies who want to hire recruiter on monthly basis </p>
                                        <Link to='/contact-us/rpo'><button className='plan-desc-row2'>Customize Now</button></Link>
                                    </div>
                                </div> */}
                            </div>
                            
                        </div>
                       {/* <div className='pricing-plans-extra'>
                            <p>For enterprise deals, click on the <Link to='/contact-us/enterprise'>Link</Link> to get customised pricing.</p>
                        </div> */}
                        
                        <div className='ts-verified-list-div'>
                                <p>* All TS verified candidates: </p>
                                <ul className='ts-verified-list'>
                                    <li>Dedicated Recruiter | </li>
                                    <li>Committed TAT | </li>
                                    <li>Interview Assistance | </li>
                                    <li>Validate Resumes - TS verified | </li>
                                    <li>Pyschometric Test | </li>
                                    <li>Feedback metrics </li>
                                </ul>
                            </div>
                        {/* <div className='pricing-page-content-2 pricing-page-content'>
                        <p>Our SRSFC Model is driven by the SPEEED delivery framework with multi-layer Feedback mechanism.
                                Through this process we intend to become your native partner by helping and assisting you in
                                recruitment activities so that you can focus on your core expertise &amp; skills to achieve milestones.
                                With lot of honesty we position ourselves as supporting partners.</p>
                            <p>All these sounds like very costly affair - for which we have taken every possible step to reduce the
                                burden from you. Our “you grow – we grow” model will help us to deliver the taken responsibility as per
                                your expectation but in an ultra-cost effective mode. Select from the best plan that suits your need and
                                sit back to experience our service delivery.</p>
                            <div className='ts-verified-list-div'>
                                <p>* All TS verified candidates: </p>
                                <ul className='ts-verified-list'>
                                    <li> <FontAwesomeIcon className="ts-verified-list-item" icon={faCheckCircle}/>Dedicated Recruiter</li>
                                    <li><FontAwesomeIcon className="ts-verified-list-item" icon={faCheckCircle}/>Committed TAT</li>
                                    <li> <FontAwesomeIcon className="ts-verified-list-item" icon={faCheckCircle}/>Interview Assistance</li>
                                    <li> <FontAwesomeIcon className="ts-verified-list-item" icon={faCheckCircle}/>Validate Resumes - TS verified</li>
                                    <li><FontAwesomeIcon className="ts-verified-list-item" icon={faCheckCircle}/>Pyschometric Test</li>
                                    <li><FontAwesomeIcon className="ts-verified-list-item" icon={faCheckCircle}/>Feedback metrics</li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            )
        }
    }
}

export default Pricing;