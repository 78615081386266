import './ExecutiveSearch.css';
import execProcessImg from '../images/Executive-Search.png';
import React, { Component } from 'react';
import CommonHeader  from '../../ComonHeader/CommonHeader';
import Header  from '../../Header/Header';
import { faDotCircle, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

class ExecutiveSearch extends Component { 
    constructor(props) {
        super(props);
        this.state= {
            imageSrc: execProcessImg,
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false
        }
    }
    render() {
        if(this.state.isMobile) {
            return(
                <div>
                    <Header/>
                    <div className='m-es-header-image'></div>
                    <div className='m-exec-search-main-container'>
                        <div className='m-perm-staffing-header'>
                            <h3>Executive Search</h3>
                        </div>
                        <div className='m-exec-search-content'>
                            <p>Through our service of Executive search, we identify and recruit executives for leadership positions 
                                within your organization.</p>
                                <p>Our executive search process begins with the development of a search mandate, which
                                outlines the specific requirements and responsibilities of the position being filled, as well
                                as the desired skills, experience, and personal characteristics of the ideal candidate.
                                We then use a variety of methods to identify and attract potential candidates, including
                                advertising the position, networking, and headhunting.</p>  
                            <p>Once a pool of candidates has been identified, we assess their suitability for the role
                                through a series of interviews, reference checks, and other assessment methods. Then
                                present a shortlist of qualified candidates to the client organization, who will make the
                                final decision on who to hire.</p>
                            <div className="exe-search-box-1-question"><b>Why should you opt for Executive Search from tekscout?</b></div>
                            <div class="m-es-sticky-notes-section">
                                        <div class="m-es-sticky-notes m-es-notes-1">
                                            <p>Access to Experts / Expertise</p>
                                        </div>
                                        <div class="m-es-sticky-notes m-es-notes-2">
                                            <p> Saving – Time, Effort & Resource</p>
                                        </div>
                                        <div class="m-es-sticky-notes m-ps-notes-3">
                                            <p> Wider Reach</p>
                                        </div>
                                        <div class="m-es-sticky-notes m-ps-notes-4">
                                            <p>Cost – effective</p>
                                        </div>
                                        <div class="m-es-sticky-notes m-ps-notes-5">
                                            <p>Confidentiality</p>
                                        </div>
                                        <div class="m-es-sticky-notes m-es-notes-6">
                                            <p>Unbiased</p>
                                        </div>
                            </div>
                            <p>Executive search can be a time-consuming and costly process, but it is often necessary for 
                                organizations seeking to fill critical leadership roles. The expertise and resources of our 
                                firm can ensure that the right candidate is identified and hired for the position.</p>
                            <img src={this.state.imageSrc} className="m-exec-searchprocess" alt="exec-searchprocess"></img>
                        </div>
                        <div className='m-know-more-link es-know-more'>
                            <Link to='/contact-us/inquiry/executive-search'>
                                <button>Know More<FontAwesomeIcon className="m-know-more-arrow" icon={faLongArrowAltRight}/></button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <div>
                    <CommonHeader/>
                    <div className='es-header-image'></div>
                    <div className='exec-search-main-container'>
                    <div className='exec-search-header'>
                            <h3>Executive Search</h3>
                        </div>
                    <div className='exec-search-content'>
                            <p>Through our service of Executive search, we identify and recruit executives for leadership positions 
                                within your organization.</p>
                            <p>Our executive search process begins with the development of a search mandate, which
                                outlines the specific requirements and responsibilities of the position being filled, as well
                                as the desired skills, experience, and personal characteristics of the ideal candidate.
                                We then use a variety of methods to identify and attract potential candidates, including
                                advertising the position, networking, and headhunting.</p>
                            <p>Once a pool of candidates has been identified, we assess their suitability for the role
                                through a series of interviews, reference checks, and other assessment methods. Then
                                present a shortlist of qualified candidates to the client organization, who will make the
                                final decision on who to hire.</p>
                                <p>Executive search can be a time-consuming and costly process, but it is often necessary for
                                organizations seeking to fill critical leadership roles. The expertise and resources of our firm can
                                ensure that the right candidate is identified and hired for the position.</p>
                        
                        
                                <div className="exe-search-box-1-question"><b>Why should you opt for Executive Search from tekscout?</b></div>
                                    <div class="es-sticky-notes-top es-sticky-notes-section">
                                        <div class="es-sticky-notes es-sticky-notes-1-1">
                                            <p>Access to Experts / Expertise</p>
                                        </div>
                                        <div class="es-sticky-notes es-sticky-notes-1-2">
                                            <p>Saving – Time, Effort & Resource</p>
                                        </div>
                                        <div class="es-sticky-notes es-sticky-notes-1-3">
                                            <p>Wider Reach</p>
                                        </div>
                                    </div>
                                    <div class="es-sticky-notes-section es-sticky-notes-bottom">
                                        <div class="es-sticky-notes es-sticky-notes-2-1">
                                            <p>Cost – effective</p>
                                        </div>
                                        <div class="es-sticky-notes es-sticky-notes-2-2">
                                            <p>Confidentiality</p>
                                        </div>
                                        <div class="es-sticky-notes es-sticky-notes-2-3">
                                            <p>Unbiased</p>
                                        </div>
                                    </div>
                        <div className='es-process-steps-container'>
                            <h4>
                                <b>Our Process</b>
                            </h4>
                            <div  className="exec-searchprocess"></div>
                        </div>
                    </div>
                        <div className='know-more-link es-know-more'>
                            <Link to='/contact-us/inquiry/executive-search'>
                                <button>Know More<FontAwesomeIcon className="m-know-more-arrow" icon={faLongArrowAltRight}/></button>
                            </Link>
                        </div>
                    </div>
                </div>
            )
        }
        
    }
}

export default ExecutiveSearch;